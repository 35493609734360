import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import Header from './components/Header';
import PageNotFound from './components/PageNotFound';
import About from './components/About';
import Contact from './components/Contact';
import WhyUs from './components/WhyUs';
import Services from './components/Services';
import Footer from './components/Footer';
import SupervisionAndPeerSupport from './components/SupervisionAndPeerSupport';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Header.css';

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<div>
					<Header />
					<Switch>
						<Route path='/' component={Home} exact />
						<Route path='/about' component={About} exact />
						<Route path='/why-us' component={WhyUs} exact />
						<Route path='/services' component={Services} exact />
						<Route path='/contact' component={Contact} exact />
						<Route path='/supervision-and-peer-support' component={SupervisionAndPeerSupport} exact />
						<Route component={PageNotFound} />
					</Switch>
					<Footer />
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
