import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

export default class Contact extends Component {
   render() {
      return (
         <div style={{ marginTop: '50px'}} className='goldDiv contactDiv'>
            <Grid>
               <Row>
                  <Col xs={12} sm={6}>
                     <div style={{ textAlign: 'center', fontSize: '65px', padding: '5%' }}>
                        Contact us
                        </div>
                     <h3 style={{ textAlign: 'center' }}>Our team will get back to you quickly</h3>
                  </Col>
                  <Col xs={12} sm={6}>
                     <div style={{ padding: '6%', color: '#0A0A0A', fontSize: '15px' }}>
                        <p><span class="glyphicon glyphicon-envelope" aria-hidden="true"></span><a href="mailto:gvkconsulting@bigpond.com" style={{ paddingLeft: '5%' }}>gvkconsulting@bigpond.com</a></p>
                        <p><span class="glyphicon glyphicon-earphone" aria-hidden="true"></span><a href="tel:02 99185244" style={{ paddingLeft: '5%' }}>02 9918 5244</a></p>
                        <p><span class="glyphicon glyphicon-phone" aria-hidden="true"></span><a href="tel:+61 421061239" style={{ paddingLeft: '5%' }}>+61 421 061 239</a></p>
                        <p><span class="glyphicon glyphicon-print" aria-hidden="true"></span><a href="fax:02 99185326" style={{ paddingLeft: '5%' }}>02 9918 5326</a></p>
                        <p><i class="fa fa-linkedin"></i><a href='https://www.linkedin.com/in/wendy-klaassen-07b16a16/' style={{ paddingLeft: '5%' }}>LinkedIn</a></p>
                     </div>
                  </Col>
               </Row>
            </Grid>
         </div>
      )
   }
}