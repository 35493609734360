import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

export default class About extends Component {
   render() {
      return (
         <div style={{ marginTop: '50px', fontSize: '20px' }} className='creamDiv'>
            <Grid fluid>
               <Row>
                  <Col xs={12} sm={9}>
                     <Row>
                        <Col xs={12} sm={12} className='goldDiv'>
                           <h1 style={{ textAlign: 'center', padding: '2%' }}>Wendy and GVK's Story</h1>
                           <p>Wendy has been conducting and managing investigations and assisting agencies with complaints and dispute resolution since 1990. She has consulted to Federal, State, Local Government and non-government agencies on investigations, grievance management, probity, workplace safety and management/business reviews for almost thirty years.</p>
                           <p>She has also developed investigation manuals and delivered training to key NSW public sector agencies on investigation, dispute resolution, complaint handling and corruption prevention techniques and strategies, at all times to the highest standards of professionalism, business ethics and service delivery and value.</p>
                        </Col>
                        <Col xs={12} sm={12} className='blackDiv'>
                           <h1 style={{ textAlign: 'center', padding: '2%' }}>Professional Experience</h1>
                           <p>Wendy has a background in the NSW public sector, working primarily in the Justice portfolio (Corrective Services/Juvenile Justice), where she worked as a Psychologist, Community Corrections Officer and Manager of Professional Conduct.</p>
                           <p>Wendy has also worked in the area of employee relations and legislative reform within the NSW public sector. Wendy has assisted a number of government agencies to streamline their disciplinary process, rewriting their disciplinary and investigation guidelines.</p>
                           <p>Wendy has provided high level advice in relation to misconduct, performance and grievance issues. Wendy also provides mentoring services in relation to these areas, and has conducted organisational health and safety checks and management reviews. These reviews have focused on bullying and harassment, employment relations, communication, safety and operational issues.</p>
                           <p>Wendy has consulted to and assisted multiple, diverse agencies in relation to complaint management and dispute resolution.</p>
                        </Col>
                     </Row>
                  </Col>
                  <Col className='creamDiv' xs={12} sm={3} height='100%'>
                     <div style={{ padding: '5%', textAlign: 'center' }}>
                        <img src='/images/wendy.jpeg' alt='' height='100%' width='100%' style={{ borderRadius: '50%' }} className='card' />
                     </div>
                     <div style={{ padding: '5%', color: '#0A0A0A', fontSize: '13.5px' }}>
                        <p><span class="glyphicon glyphicon-envelope" aria-hidden="true"></span><a href="mailto:wendyklaassen@gvkconsulting.com.au" style={{ paddingLeft: '5%' }}>wendyklaassen@gvkconsulting.com.au</a></p>
                        <p><i class="fa fa-linkedin"></i><a href='https://www.linkedin.com/in/wendy-klaassen-07b16a16/' style={{ paddingLeft: '5%' }}>LinkedIn</a></p>
                        <p><span class="glyphicon glyphicon-earphone" aria-hidden="true"></span><a href="tel:+61 421061239" style={{ paddingLeft: '5%' }}>+61 421 061 239</a></p>
                        <p style={{ paddingTop: '5%' }}>
                           Qualifications
                           <ul>
                              <li> BA (Hons) degree in Psychology and a Bachelor of Laws.</li>
                              <li> Certificate III in Investigative Services (including surveillance).</li>
                              <li> Certificate IV in Workplace Training.</li>
                              <li> Full (master) Private Inquiry Agents licence under the NSW Commercial Agents and Private Inquiry Agents Act 2004.</li>
                              <li> Member NSW Corruption Prevention Network.</li>
                              <li> Working with Children Check (WWC 0266778E)</li>
                              <li> Member NSW Government Preferred Supplier Performance and Management Scheme</li>
                           </ul>
                        </p>
                     </div>
                  </Col>
               </Row>
            </Grid>
         </div>
      )
   }
}