import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';

export default class Header extends Component {
	render() {
		// Render under image if location is home
		return (
			<Navbar fixedTop collapseOnSelect>
				<Navbar.Header>
					<Navbar.Brand>
						<a href="/">
							<img src='/images/logo-no-background.svg' alt='GVK Consulting Logo' style={{ width: 'auto', marginTop: '-11px', height: '45px' }} />
						</a>
					</Navbar.Brand>
					<Navbar.Toggle />
				</Navbar.Header>
				<Navbar.Collapse>
					<Nav pullRight>
						<NavItem eventKey={1} href="/about">
							Who we are
      				</NavItem>
						<NavItem eventKey={2} href="/why-us">
							Why choose us
      			</NavItem>
						<NavItem eventKey={2} href="/services">
							Investigation Services
      			</NavItem>
						<NavItem eventKey={2} href="/supervision-and-peer-support">
							Supervision and Peer Support
      				</NavItem>
						<NavItem eventKey={1} href="/contact">
							Contact us
      				</NavItem>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}