import React, { Component } from 'react';

export default class Footer extends Component {
   render() {
      return (
         <div style={{ textAlign: 'center', padding: '1%', background: 'black', color: '#FFDD03' }}>
            <p>Copyright <span class="glyphicon glyphicon-copyright-mark" aria-hidden="true"></span> 2020 GVK Consulting PTY LTD
            </p>
         </div>
      )
   }
}