import React, { Component } from 'react';

export default class WhyUs extends Component {
   render() {
      return (
         <div style={{ marginTop: '50px', fontSize: '20px' }}>
            <div className='goldDiv' style={{ padding: '3.5%' }}>
               <div style={{ fontSize: '50px', textAlign: 'center' }}>Why choose us?</div>
            </div>
            <div className='blackDiv' style={{ padding: '2%' }}>
               <h1 style={{ color: '#FBFCD4' }}>Experience and Resources</h1>
               <p>With over 30 years of professional experience in the field of investigations, grievance management, employment law and legal policy, Wendy brings a breadth of knowledge to the consultancy.</p>
               <p>With an internal administration and investigation support team and access to external forensic experts, GVK Consulting has the resources to provide complete solutions to even the most complex matters.</p>
               <p>All matters are able to be dealt with swiftly and effectively, saving your organisation time and minimising risk.</p>
            </div>
            <div className='creamDiv' style={{ padding: '2%' }}>
               <h1 style={{ color: '#151515' }}>Integrity</h1>
               <p>GVK Consulting has developed a reputation for independent ethical consulting in all areas of workplace investigations.</p>
               <p>Every project is approached with the utmost integrity with an emphasis on confidentiality, impartiality and objectivity.</p>
            </div>
            <div className='goldDiv' style={{ padding: '2%' }}>
               <h1 style={{ color: 'white' }}>Procedual Fairness and Sensitivity</h1>
               <p>Each team member approaches their work with an emphasis on procedural fairness while balancing the need to protect informants and whistleblowers.</p>
               <p>Every party is treated with dignity and respect.</p>
            </div>
            <div className='blackDiv' style={{ padding: '2%' }}>
               <h1 style={{ color: '#FBFCD4' }}>Security</h1>
               <p>GVK Consulting recognises and values the importance of information security.</p>
            </div>
         </div>
      )
   }
}