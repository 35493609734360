import React, { Component } from 'react';

export default class SupervisionAndPeerSupport extends Component {
  render() {
    return (
      <div className='goldDiv' style={{ marginTop: '50px', fontSize: '20px' }}>
        <h1 style={{ textAlign: 'center', paddingTop: '2%' }}>Professional Supervision and Peer Support Services</h1>
        <div style={{ padding: '2%' }}>
          <h2>Rationale</h2>
          <p>Investigations and complaint handling is currently an unregulated industry comprised of practitioners with varying skill levels and no professional governing body. It is also an area of practice which can be extremely stressful and isolated as the majority work as freelance practitioners with limited opportunities to discuss their work. As a result, many investigators and complaint handlers rely on close friends and colleagues for support.</p>
          <p>Professional supervision is an enabling process that allows participants to reflect on work practice, review and evaluate their work and problem solve through discussion, report and/or observation. It also provides an opportunity to explore issues, emotions and ethical dilemmas which arise from their work. Investigations and complaint handling can be demanding as witnessing the distress of others can impact on emotional wellbeing.</p>
          <p>The objectives of professional supervision are to develop competent, accountable performance, to ensure quality service delivery and provide selfcare to practitioners through increased resilience and reduced work related stress.</p>
          <p>Peer support is designed to offer and safe environment for seasoned investigators and complaint handlers to reflect on their practice, brainstorm ideas, debrief during and after stressful events and improve self-care and well-being.</p>
          <p style={{ textDecoration: 'underline' }}>Key Elements</p>
          <p>Trust is central to the supervisory and peer relationship as it allows participants to introduce what they may perceive as mistakes, shortcomings or a lack of resilience into a non-judgemental and safe forum.</p>
          <p>Confidentiality is also a key platform in the supervision process and reflects the confidential nature of investigations, mediation and complaint handling. It also allows the individual to disclose personal and professional concerns that they may not feel comfortable raising with their manager.</p>
          <p style={{ textDecoration: 'underline' }}>Principles</p>
          <p>Often investigations and compliant handling are judged by ‘a good result’, with little credence placed on the mental health effects on each of the parties involved in the process, especially the investigator, complaint handler or case manager. Under this model, a good outcome is defined by a robust and ethical application of a fair process where regard for the well-being of the individual.</p>
          <p style={{ textDecoration: 'underline' }}>Benefits to individuals</p>
          <ul>
            <li>Opportunities to reflect and link knowledge and practice</li>
            <li>Increased confidence and resilience</li>
            <li>Skill development</li>
            <li>Greater awareness and understanding</li>
            <li>Practice validation</li>
            <li>Reduced stress</li>
          </ul>
          <p style={{ textDecoration: 'underline' }}>Benefits to organizations</p>
          <ul>
            <li>Improved service quality</li>
            <li>Higher levels of employee wellbeing and satisfaction</li>
            <li>Minimise risk</li>
          </ul>
          <p style={{ textDecoration: 'underline' }}>Delivery model</p>
          <ul>
            <li>Personal (one-on-one) supervision and peer support</li>
            <li>Group supervision</li>
            <li>Observation</li>
          </ul>
          <p>Supervision and peers support can occur remotely via Skype and or telephone or can be conducted face to face in our Sydney offices.</p>
          <p>Observation sessions are an adjunct to coaching and are delivered in house.</p>
        </div>
      </div>
    )
  }
}