import React, { Component } from 'react';

export default class Services extends Component {
   render() {
      return (
         <div className='goldDiv' style={{ marginTop: '50px', fontSize: '20px' }}>
            <h1 style={{ textAlign: 'center', paddingTop: '2%' }}>Our Services</h1>
            <div style={{ padding: '2%' }}>
               <p>GVK Consulting leads and conducts investigations into a broad range of sensitive and complex matters, including ICAC and Ombudsman investigations for government agencies, schools and the not-for-profit sector.</p>
               <p>GVK also works with a number of large law firms to provide investigation services for their clients. Matters dealt with include:</p>
               <ul>
                  <li>corrupt conduct</li>
                  <li>fraud, misappropriation and misuse of resources</li>
                  <li>breaches of codes of conduct and internal policy</li>
                  <li>bullying and harassment</li>
                  <li>assault and violence within the workplace</li>
                  <li>grievances</li>
                  <li>inappropriate use of internet and e-mail</li>
                  <li>public interest disclosures</li>
                  <li>sexual assault</li>
                  <li>client/customer complaints</li>
                  <li>child protection/reportable conduct matters</li>
                  <li>allegations of age, gender and racial discrimination</li>
                  <li>Code of Conduct Reviews for Local Councils</li>
                  <li>Preliminary assessments</li>
               </ul>
               <p>GVK can also assemble and lead a team of experts to assist in large scale, complex matters at short notice.</p>
               <p>GVK is available to assist with dispute resolution.</p>
            </div>
         </div>
      )
   }
}