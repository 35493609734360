import React, { Component } from "react";
import { Grid, Row, Col, Carousel } from "react-bootstrap";
import differenceInYears from "date-fns/differenceInYears";

export default class Home extends Component {
  render() {
    const numberOfYearsExperience = differenceInYears(
      new Date(),
      new Date("1990-01-01")
    );
    return (
      <div>
        <div className="image-container">
          <img
            src="/images/landing-min.jpg"
            alt="city at night"
            style={{ height: "100vh", width: "100%", objectFit: "cover" }}
          />
          <div className="image-centered">
            GVK Consulting specialises in workplace investigations, reviews and
            complaint management. With over {numberOfYearsExperience} years of
            experience in the sector, you are in safe hands.
          </div>
          <div className="image-bottom-right">
            <img
              src="/images/logo-no-background.svg"
              alt="GVK Consulting Logo"
              style={{
                width: "auto",
                height: "75px",
                paddingBottom: "5%",
                paddingRight: "5%",
              }}
            />
          </div>
        </div>
        <div className="goldDiv">
          <div className="homeText">
            Ethically consulting to Federal, State and Local Government, as well
            as Non-Government Agencies on investigations, grievance management,
            probity, workplace safety and management reviews.
            <div style={{ textAlign: "right" }}>
              <a href="/services" style={{ color: "white" }}>
                See our services
              </a>
            </div>
          </div>
        </div>
        <div className="blackDiv">
          <div className="homeText">
            <div>
              Wendy Klaassen is the Director of GVK consulting. Wendy has been
              conducting and managing investigations and assisting agencies with
              complaints and dispute resolution since 1988.
            </div>
            <div style={{ textAlign: "right" }}>
              <a href="/about" style={{ color: "#FBFCD4" }}>
                Read more about Wendy and GVK
              </a>
            </div>
          </div>
        </div>
        <div className="creamDiv">
          <Grid style={{ padding: "5%" }}>
            <Row>
              <Col xs={12} sm={4}>
                <div className="homeText">
                  <div>Here are some of our clients.</div>
                  <div style={{ marginTop: "10px" }}>
                    <a href="/about" style={{ color: "#FBC403" }}>
                      Here's why you should choose us.
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={8}>
                <Carousel indicators={false}>
                  <Carousel.Item>
                    <div className="homeLogos">
                      <img alt="" src="/images/dod-logo.png" />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="homeLogos">
                      <img alt="" src="/images/nsw-gov-coloured.png" />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="homeLogos">
                      <img alt="" src="/images/uts-logo.png" />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="homeLogos">
                      <img alt="" src="/images/FRNSW-logo.png" />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="homeLogos">
                      <img alt="" src="/images/npws-logo.png" />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
